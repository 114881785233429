import React from 'react';
import { Wrapper } from 'src/components/GlobalUI';
import {
	PortfolioWrapper,
	PortfolioList,
	PortfolioItem,
	PortfolioTitleCss,
} from './Portfolio.dumb';
import Title from 'src/components/Title/Title';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

export const portfolioImages = graphql`
	fragment portfolioImages on File {
		childImageSharp {
			fixed(height: 60, quality: 100) {
				...GatsbyImageSharpFixed
			}
		}
	}
`;

export const portfolioMobileImages = graphql`
	fragment portfolioMobileImages on File {
		childImageSharp {
			fixed(height: 40, quality: 100) {
				...GatsbyImageSharpFixed
			}
		}
	}
`;

const Portfolio: React.FC = () => {
	const images = useStaticQuery(graphql`
		query Images {
			allFile(filter: { absolutePath: { regex: "img/portfolio/" } }) {
				nodes {
					...portfolioImages
					name
				}
			}
			allFile(filter: { absolutePath: { regex: "img/portfolio/" } }) {
				edges {
					node {
						...portfolioMobileImages
						name
					}
				}
			}
		}
	`);

	const result = images.allFile.edges.reduce((ac, { node: el }) => {
		ac[el.name.replace('@2x', '')] = [el.childImageSharp.fixed];
		return ac;
	}, {});

	images.allFile.nodes.reduce((ac, el) => {
		ac[el.name.replace('@2x', '')].push({
			...el.childImageSharp.fixed,
			media: `(min-width: 992px)`,
		});
		return ac;
	}, result);

	return (
		<PortfolioWrapper id="portfolio">
			<Wrapper>
				<Title css={PortfolioTitleCss}>Portfolio</Title>
				<PortfolioList>
					<PortfolioItem>
						<a target="_blank" href="https://www.ns8.com/">
							<Img fixed={result['Nseight']} alt="NS8" />
						</a>
					</PortfolioItem>
					<PortfolioItem>
						<a target="_blank" href="https://virgilsecurity.com/">
							<Img fixed={result['Virgil']} alt="Virgil Security" />
						</a>
					</PortfolioItem>
					<PortfolioItem>
						<a target="_blank" href="https://www.shevirah.com/">
							<Img fixed={result['Shevirah']} alt="shevirah" />
						</a>
					</PortfolioItem>
					<PortfolioItem>
						<a target="_blank" href="https://runsafesecurity.com/">
							<Img fixed={result['Runsafe']} alt="Runsafe" />
						</a>
					</PortfolioItem>
					<PortfolioItem>
						<a target="_blank" href="https://www.linkedin.com/company/lootify-inc/">
							<Img fixed={result['Lootify']} alt="Lootify" />
						</a>
					</PortfolioItem>
				</PortfolioList>
				<PortfolioList>
					<PortfolioItem>
						<a target="_blank" href="https://www.threatswitch.com/">
							<Img fixed={result['Tswitch']} alt="threatswitch" />
						</a>
					</PortfolioItem>
					<PortfolioItem>
						<a target="_blank" href="https://hyperqube.io/">
							<Img fixed={result['Hyper']} alt="Hyper" />
						</a>
					</PortfolioItem>
					<PortfolioItem>
						<a target="_blank" href="https://www.syncurity.net/">
							<Img fixed={result['Sync']} alt="Syncurity" />
						</a>
					</PortfolioItem>
					<PortfolioItem>
						<a target="_blank" href="https://www.reliaquest.com/reliaquest-and-threatcare/">
							<Img fixed={result['Tcare']} alt="Threatcare" />
						</a>
					</PortfolioItem>
				</PortfolioList>
			</Wrapper>
		</PortfolioWrapper>
	);
};

export default Portfolio;
