import React from 'react';
import { globalHistory } from '@reach/router';
import { Helmet } from 'react-helmet';

export interface SeoWrapperProps {
	description: string;
	title: string;
	keywords: string[];
}

const SeoWrapper: React.FC<SeoWrapperProps> = ({ description, title, keywords, children }) => {
	return (
		<React.Fragment>
			<Helmet>
				<title>{title}</title>
				<meta name="description" content={description} />
				<meta name="keywords" content={keywords.join(', ')} />
				<link rel="canonical" href={globalHistory.location.href} />
				<meta property="og:title" content={title} />
				<meta property="og:description" content={description} />
				<meta name="twitter:title" content={title} />
				<meta name="twitter:description" content={description} />
			</Helmet>
			{children}
		</React.Fragment>
	);
};

export default SeoWrapper;
