import React from 'react';
import { Wrapper } from 'src/components/GlobalUI';
import {
	ThesisWrapper,
	ThesisRow,
	ThesisContent,
	ThesisImg,
	ThesisHeadline,
	ThesisDescription,
	ThesisText,
} from './Thesis.dumb';
import Title from 'src/components/Title/Title';
import IotPath, { ReactComponent as IotIcon } from 'img/Iot.svg';
import SmartPath, { ReactComponent as SmartIcon } from 'img/Smart.svg';
import CyberPath, { ReactComponent as CyberIcon } from 'img/Cyber.svg';
import InsurancePath, { ReactComponent as InsuranceIcon } from 'img/Insurance.svg';

const Thesis: React.FC = () => {
	return (
		<ThesisWrapper id="thesis">
			<Wrapper>
				<Title>Thesis</Title>
				<ThesisRow>
					<ThesisImg>
						<IotIcon />
					</ThesisImg>
					<ThesisContent backgroundImg={IotPath}>
						<ThesisHeadline className="title">IoT</ThesisHeadline>
						<ThesisDescription>
							There will be trillions (with a T) of devices/sensors deployed globally.
						</ThesisDescription>
						<ThesisText>
							What will they power? How would they be secured, connected, exploited, managed?
						</ThesisText>
						<ThesisText>
							Consumer devices are ahead of the curve here however large enterprises, governments
							and healthcare providers are particularly behind in regards to deploying and
							exploiting connectivity.
						</ThesisText>
					</ThesisContent>
				</ThesisRow>
				<ThesisRow>
					<ThesisContent backgroundImg={SmartPath}>
						<ThesisHeadline className="title">Smart Everything</ThesisHeadline>
						<ThesisDescription>
							Global real-estate portfolios are valued over $200 trillion dollars.
						</ThesisDescription>
						<ThesisText>
							Smart Cities, buildings, homes are coming but this market has only started to appear.
							How would one build, secure, and maintain smart cities?
						</ThesisText>
						<ThesisText>
							Large enterprises are only now discovering the efficiencies of connected
							infrastructure and we are still far away from “smart infrastructure”. We see this as a
							big investment opportunity and one of the most promising areas of investment.
						</ThesisText>
					</ThesisContent>
					<ThesisImg>
						<SmartIcon />
					</ThesisImg>
				</ThesisRow>
				<ThesisRow>
					<ThesisImg>
						<CyberIcon />
					</ThesisImg>
					<ThesisContent backgroundImg={CyberPath}>
						<ThesisHeadline className="title">Cybersecurity</ThesisHeadline>
						<ThesisDescription>
							As connectivity increases security firmly moves from “nice to have” to “must-have” in
							every product.
						</ThesisDescription>
						<ThesisText>
							However, software and hardware developers are woefully under-prepared. Existing
							certification regimes attempt to replace actual security with endless checklists and
							compliance items which often have very little to do with actual data security.
						</ThesisText>
					</ThesisContent>
				</ThesisRow>
				<ThesisRow>
					<ThesisContent backgroundImg={InsurancePath}>
						<ThesisHeadline className="title">Insurance</ThesisHeadline>
						<ThesisDescription>
							One of the greatest promises of IoT is its ability to have nearly constant control of
							infrastructure.
						</ThesisDescription>
						<ThesisText>
							From a classic insurance perspective, this can be both very good or very bad. Many
							insurance businesses such as car insurance are likely to experience significant
							declines as safer alternatives to human-operated vehicles are deployed.
						</ThesisText>
						<ThesisText>
							On the other hand, new fields such as cyber insurance, predictive insurance products,
							and crowd insurance are quickly emerging helped by ubiquitous data sources and
							advanced analytics. The insurance market will be markedly different and we look
							forward to playing a part in this transformation.
						</ThesisText>
					</ThesisContent>
					<ThesisImg>
						<InsuranceIcon />
					</ThesisImg>
				</ThesisRow>
			</Wrapper>
		</ThesisWrapper>
	);
};

export default Thesis;
