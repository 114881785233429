import styled from 'styled-components';
import { WIDTH_BREAKPOINT_MD } from 'src/utils/constants';

export const TitleContent = styled.h3`
	color: #16110d;
	font-family: 'Roboto Mono', sans-serif;
	font-size: 32px;
	font-weight: 500;
	line-height: 22px;
	margin-top: 0;
	text-align: left;

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		font-size: 24px;
		line-height: 32px;
	}
`;
