import React, { useEffect } from 'react';
import { PageProps } from 'gatsby';
import Layout from 'src/layout/Layout';
import Header from 'src/sections/Header/Header';
import Intro from 'src/sections/Intro/Intro';
import Portfolio from 'src/sections/Portfolio/Portfolio';
import Thesis from 'src/sections/Thesis/Thesis';
import Team from 'src/sections/Team/Team';
import Footer from 'src/sections/Footer/Footer';
import SeoWrapper from 'src/components/Seo';
import seoPage from 'src/content/seo.yml';
import MobileNav from 'src/sections/Header/MobileNav';

const IndexPage: React.FC<PageProps> = () => {
	useEffect(() => {
		const titles = document.querySelectorAll('.title');
		titles.forEach(el => el.setAttribute('text', el.innerHTML));
	}, []);

	return (
		<SeoWrapper
			title={seoPage.tab_title}
			description={seoPage.meta_description}
			keywords={seoPage.meta_keywords}
		>
			<Layout>
				<Header />
				<MobileNav />
				<Intro />
				<Portfolio />
				<Thesis />
				<Team />
				<Footer />
			</Layout>
		</SeoWrapper>
	);
};

export default IndexPage;
