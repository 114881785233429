import React from 'react';
import { Nav, NavLink } from './Header.dumb';

interface NavigationProps extends React.HTMLAttributes<HTMLElement> {
	setModal?: (boolean) => void;
}

const Navigation: React.FC<NavigationProps> = ({ setModal, ...props }) => {
	const closeModal = () => {
		setModal && setModal(false);
	};

	return (
		<Nav {...props}>
			<NavLink onClick={closeModal} href="#portfolio">
				Portfolio
			</NavLink>
			<NavLink onClick={closeModal} href="#thesis">
				Thesis
			</NavLink>
			<NavLink onClick={closeModal} href="#team">
				Team
			</NavLink>
			<NavLink target="_blank" href="mailto:info@kumocapital.com">
				Contact us
			</NavLink>
		</Nav>
	);
};

export default Navigation;
