import styled from 'styled-components';
import { WIDTH_BREAKPOINT_LG, WIDTH_BREAKPOINT_MD } from 'src/utils/constants';

export const Wrapper = styled.div`
	box-sizing: border-box;
	margin: 0 auto;
	max-width: 100%;
	width: 100%;

	@media (min-width: ${WIDTH_BREAKPOINT_MD}) {
		max-width: 960px;
	}

	@media (min-width: ${WIDTH_BREAKPOINT_LG}) {
		max-width: 1060px;
	}

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		padding: 0 20px;
	}
`;

export const Section = styled.section`
	display: flex;
`;
