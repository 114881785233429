import { css } from 'styled-components';

export const titleStyle = (color: string, shift = '3px') => css`
	position: relative;
	z-index: 0;

	::before {
		color: transparent;
		content: attr(text);
		display: block;
		left: ${shift};
		position: absolute;
		-webkit-text-stroke: 2px ${color};
		top: ${shift};
		z-index: -1;
	}
`;
