import React from 'react';
import { Wrapper } from 'src/components/GlobalUI';
import { HeaderWrapper, HeaderContent } from './Header.dumb';
import { ReactComponent as LogoIcon } from 'img/Logo.svg';
import Navigation from './Navigation';

const Header: React.FC = () => {
	return (
		<HeaderWrapper>
			<Wrapper>
				<HeaderContent>
					<a href="#intro">
						<LogoIcon />
					</a>
					<Navigation className="desktop" />
				</HeaderContent>
			</Wrapper>
		</HeaderWrapper>
	);
};

export default Header;
