import React from 'react';
import { Wrapper } from 'src/components/GlobalUI';
import { TeamWrapper, TeamLink, TeamLine } from './Team.dumb';
import Title from 'src/components/Title/Title';
import {
	Row,
	ColSm,
	ColLg,
	ColContainer,
	ColContent,
	TeamHeadline,
	TeamDate,
	TeamDescription,
	ImgContainer,
} from '../Team/Team.dumb';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

export const squareImage = graphql`
	fragment squareImage on File {
		childImageSharp {
			fixed(height: 200, width: 200, quality: 100) {
				...GatsbyImageSharpFixed
			}
		}
	}
`;

export const squareSmallImage = graphql`
	fragment squareSmallImage on File {
		childImageSharp {
			fixed(height: 95, width: 95, quality: 100) {
				...GatsbyImageSharpFixed
			}
		}
	}
`;

const Team: React.FC = () => {
	const data = useStaticQuery(graphql`
		query {
			Dain: file(sourceInstanceName: { eq: "img" }, name: { eq: "Dain@2x" }) {
				...squareImage
			}
			DainS: file(sourceInstanceName: { eq: "img" }, name: { eq: "Dain@2x" }) {
				...squareSmallImage
			}

			Wellman: file(sourceInstanceName: { eq: "img" }, name: { eq: "Wellman@2x" }) {
				...squareImage
			}
			WellmanS: file(sourceInstanceName: { eq: "img" }, name: { eq: "Wellman@2x" }) {
				...squareSmallImage
			}
		}
	`);

	const imgWellman = [
		data.WellmanS.childImageSharp.fixed,
		{ ...data.Wellman.childImageSharp.fixed, media: `(min-width: 768px)` },
	];

	const imgDain = [
		data.DainS.childImageSharp.fixed,
		{ ...data.Dain.childImageSharp.fixed, media: `(min-width: 768px)` },
	];

	return (
		<TeamWrapper id="team">
			<Wrapper>
				<Title>Team</Title>
				<Row>
					<ColSm>
						<div>
							<ImgContainer className="invert">
								<Img fixed={imgDain} alt="Dmitry Dain" />
							</ImgContainer>
						</div>
						<TeamHeadline className="title sm">Dmitry Dain</TeamHeadline>
						<TeamLink href="https://www.linkedin.com/in/dmitrydain/">View on LinkedIn</TeamLink>
					</ColSm>
					<ColLg>
						<TeamHeadline className="title lg">Dmitry Dain</TeamHeadline>
						<ColContainer>
							<ColContent>
								<TeamDate>2000–2003</TeamDate>
								<TeamDescription>
									The developer of first public Wi-Fi, first enterprise Wi-Fi and first airplane
									Wi-Fi. OEMEd by Apple, HP, Dell and 30 other leading manufacturers. Developer of
									Lucent Orinoco Wi-Fi Routers (later recognised as “Product Of The Year” by PC
									Magazine).
								</TeamDescription>
								<TeamDate>2004–2007</TeamDate>
								<TeamDescription>
									Technical Approach Lead for DARPA XG — developed software-defined radios and
									cognitive radio systems for the US Defence Advanced Research Projects Agency. This
									project resulted in 7 foundational patents and the creation of the “White Spaces
									Initiative”, which freed spectrum for LTE networks.
								</TeamDescription>
							</ColContent>
							<ColContent>
								<TeamDate>2006 / 2008</TeamDate>
								<TeamDescription>
									Co-founder of Kadoo and DivShare — cloud-based hosting focused firms (Box.net and
									Dropbox competitors). Dmitry grew the company to 3 million users.
								</TeamDescription>
								<TeamDate>2012–2013</TeamDate>
								<TeamDescription>
									Subject-matter expert for the US Treasury on the topic of cryptocurrency security
									and the Blockchain.
								</TeamDescription>
								<TeamDate>2014</TeamDate>
								<TeamDescription>
									Adviser to Goldman Sachs on bitcoin and blockchain security.
								</TeamDescription>
								<TeamDate>2014 — to present</TeamDate>
								<TeamDescription>
									Co-founder and CTO of Virgil Security, Inc. — a world leading company focused on
									helping developers build secure mobile apps and IoT devices.
								</TeamDescription>
							</ColContent>
						</ColContainer>
					</ColLg>
				</Row>
				<TeamLine />
				<Row>
					<ColLg>
						<TeamHeadline className="title lg">Michael W. Wellman</TeamHeadline>
						<ColContainer>
							<ColContent>
								<TeamDate>---</TeamDate>
								<TeamDescription>
									30+ years successfully delivering new technologies and market-defining products.
								</TeamDescription>
								<TeamDate>---</TeamDate>
								<TeamDescription>
									Delivered products for AMD, AOL, Apple, Ascend, Compaq, Dell, HP, IBM, Lucent,
									Microsoft, PSINet, Sony &amp; Sprint.
								</TeamDescription>
								<TeamDate>---</TeamDate>
								<TeamDescription>AOL’s first Macintosh web-browser.</TeamDescription>
							</ColContent>
							<ColContent>
								<TeamDate>---</TeamDate>
								<TeamDescription>Apple’s first 2 generations of Airport.</TeamDescription>
								<TeamDate>---</TeamDate>
								<TeamDescription>Industry’s first outdoor 802.11a Wi-Fi product.</TeamDescription>
								<TeamDate>---</TeamDate>
								<TeamDescription>Industry’s first public space Wi-Fi product.</TeamDescription>
								<TeamDate>---</TeamDate>
								<TeamDescription>Industry’s first secure enterprise Wi-Fi product.</TeamDescription>
							</ColContent>
						</ColContainer>
					</ColLg>
					<ColSm>
						<div>
							<ImgContainer>
								<Img fixed={imgWellman} alt="Michael W. Wellman" />
							</ImgContainer>
						</div>
						<TeamHeadline className="title sm">Michael W. Wellman</TeamHeadline>
						<TeamLink href="https://www.linkedin.com/in/mwellman/">View on LinkedIn</TeamLink>
					</ColSm>
				</Row>
			</Wrapper>
		</TeamWrapper>
	);
};

export default Team;
