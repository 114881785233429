import React from 'react';
import { useState } from 'react';
import {
	MobileNavWrapper,
	OpenButton,
	MobileNavOverlay,
	GlobalStyle,
	MobileNavContainer,
} from './Header.dumb';
import { ReactComponent as BurgerIcon } from 'img/Menu.svg';
import { ReactComponent as CloseIcon } from 'img/Close.svg';
import { ReactComponent as LogoIcon } from 'img/LogoS.svg';
import Navigation from './Navigation';

const MobileNav: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => {
	const [isOpen, setOpen] = useState(false);

	return (
		<MobileNavOverlay isOpen={isOpen}>
			<MobileNavWrapper {...props} isOpen={isOpen}>
				<a href="#intro">
					<LogoIcon />
				</a>
				<OpenButton onClick={() => setOpen(!isOpen)} isOpen={isOpen}>
					<BurgerIcon />
					<CloseIcon />
				</OpenButton>
				<GlobalStyle isOpen={isOpen} />
				<MobileNavContainer isOpen={isOpen}>
					<Navigation setModal={setOpen} />
				</MobileNavContainer>
			</MobileNavWrapper>
		</MobileNavOverlay>
	);
};

export default MobileNav;
