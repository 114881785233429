import styled from 'styled-components';

export const LayoutContent = styled.div`
	color: #16110d;
	display: flex;
	flex-direction: column;
	min-height: 100%;
	position: relative;

	@media (min-width: 1921px) {
		box-shadow: 0 0 5px #16110d26;
		margin: 0 auto;
		max-width: 1922px;
	}
`;
