import styled, { css } from 'styled-components';
import { Section } from 'src/components/GlobalUI';
import { WIDTH_BREAKPOINT_MD } from 'src/utils/constants';

export const PortfolioWrapper = styled(Section)`
	background: white;
	padding: 10px 0 57px;
`;

export const PortfolioList = styled.ul`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		justify-content: start;
		margin: 0 -20px;
	}
`;

export const PortfolioItem = styled.li`
	list-style: none;
	margin: 40px 20px;
`;

export const PortfolioTitleCss = css`
	margin: 10px 0 70px;

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		margin-bottom: 20px;
	}
`;
