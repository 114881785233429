import styled from 'styled-components';
import { Section } from 'src/components/GlobalUI';
import { titleStyle } from 'src/utils/styled';
import LinkedInIcon from 'img/Linkedin.svg';
import { WIDTH_BREAKPOINT_MD, WIDTH_BREAKPOINT_SM } from 'src/utils/constants';

export const TeamWrapper = styled(Section)`
	background: #ffbe2e;
	padding: 60px 0;
`;

export const Row = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 58px 0 38px;

	@media (max-width: ${WIDTH_BREAKPOINT_SM}) {
		flex-direction: column;
	}
`;

export const ColLg = styled.div`
	align-self: center;
	display: flex;
	flex-basis: 74%;
	flex-direction: column;

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		flex-basis: 100%;
		padding: 0 20px;
	}

	@media (max-width: ${WIDTH_BREAKPOINT_SM}) {
		order: 1;
		padding: 0;
	}
`;

export const ColContainer = styled.div`
	display: flex;
	justify-content: space-between;

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		flex-direction: column;
	}
`;

export const ColContent = styled.div`
	flex-basis: 46%;

	:last-child {
		flex-basis: 50%;
		margin-top: 5px;
	}

	@media (max-width: ${WIDTH_BREAKPOINT_SM}) {
		flex-basis: 100%;
	}
`;

export const ColSm = styled.div`
	display: flex;
	flex-basis: 250px;
	flex-direction: column;

	@media (max-width: ${WIDTH_BREAKPOINT_SM}) {
		flex-basis: initial;
		flex-direction: row;
		flex-wrap: wrap;
		width: 330px;
	}
`;

export const ImgContainer = styled.div`
	border-radius: 40px;
	box-shadow: 10px 10px 0 0 #ffeecc;
	display: flex;
	margin-top: 10px;
	overflow: hidden;
	width: fit-content;

	&.invert {
		@media (min-width: ${WIDTH_BREAKPOINT_SM}) {
			box-shadow: -10px 10px 0 0 #ffeecc;
			margin-left: 10px;
		}
	}

	@media (max-width: ${WIDTH_BREAKPOINT_SM}) {
		border-radius: 20px;
		margin-right: 30px;
	}
`;

export const TeamHeadline = styled.h2`
	color: #ffffff;
	font-family: 'Open Sans', sans-serif;
	font-size: 32px;
	font-weight: 700;
	line-height: 45px;
	${titleStyle('#16110D', '2px')}
	margin-top: 0;

	&.sm {
		display: none;
	}

	@media (max-width: ${WIDTH_BREAKPOINT_SM}) {
		&.sm {
			display: block;
			width: 58%;
		}

		&.lg {
			display: none;
		}
	}
`;

export const TeamDate = styled.p`
	color: #ffeecc;
	font-family: 'Roboto Mono', sans-serif;
	font-size: 12px;
	font-weight: 500;
	line-height: 15px;
	margin-bottom: 0;
`;

export const TeamDescription = styled.p`
	color: #16110d;
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	margin: 5px 0 30px;
`;

export const TeamLink = styled.a`
	background: #16110d;
	border-radius: 20px;
	color: white;
	font-family: 'Roboto Mono', sans-serif;
	font-size: 14px;
	font-weight: 500;
	line-height: 19px;
	margin-top: 30px;
	padding: 10px 20px 10px 50px;
	position: relative;
	transition: all 0.2s;
	width: fit-content;

	:hover {
		background: #393431;
	}

	::before {
		content: url(${LinkedInIcon});
		left: 18px;
		position: absolute;
		top: 7px;
	}

	@media (max-width: ${WIDTH_BREAKPOINT_SM}) {
		margin: 20px 0 40px;
	}
`;

export const TeamLine = styled.div`
	background: #ffffff;
	height: 2px;
	opacity: 0.2;
	width: 100%;
`;
