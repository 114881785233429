import styled from 'styled-components';
import { Section } from 'src/components/GlobalUI';
import { titleStyle } from 'src/utils/styled';
import { WIDTH_BREAKPOINT_SM, WIDTH_BREAKPOINT_XS } from 'src/utils/constants';

export const ThesisWrapper = styled(Section)`
	background: #ffeecc;
	padding: 110px 0 70px;

	@media (max-width: ${WIDTH_BREAKPOINT_SM}) {
		padding: 70px 0;
	}
`;

export const ThesisRow = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 40px 0;

	@media (max-width: ${WIDTH_BREAKPOINT_SM}) {
		margin: 0;
	}
`;

export const ThesisContent = styled.div`
	align-self: center;
	flex-basis: 55%;
	flex-direction: column;

	@media (max-width: ${WIDTH_BREAKPOINT_SM}) {
		flex-basis: 100%;
		padding: 120px 0 20px;
		position: relative;

		::before {
			background: url(${({ backgroundImg }) => backgroundImg}) no-repeat right top;
			background-size: 40%;
			content: '';
			height: 100%;
			left: 0;
			opacity: 0.1;
			pointer-events: none;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 0;

			@media (max-width: ${WIDTH_BREAKPOINT_XS}) {
				background-size: 70%;
			}
		}
	}
`;

export const ThesisImg = styled.div`
	align-items: center;
	align-self: center;
	display: flex;
	flex-basis: 30%;

	@media (max-width: ${WIDTH_BREAKPOINT_SM}) {
		display: none;
	}
`;

export const ThesisHeadline = styled.h2`
	color: #ffbe2e;
	font-family: 'Open Sans', sans-serif;
	font-size: 48px;
	font-weight: 700;
	line-height: 60px;
	margin: 40px 0 10px;
	${titleStyle('white')}
	@media (max-width: ${WIDTH_BREAKPOINT_SM}) {
		font-size: 35px;
		line-height: 45px;
		margin: 10px 0;
	}
`;

export const ThesisDescription = styled.p`
	color: #16110d;
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
	font-weight: 600;
	line-height: 25px;
	margin: 0 0 10px;
`;

export const ThesisText = styled(ThesisDescription)`
	font-weight: 400;

	:last-child {
		margin-bottom: 0;
	}
`;
