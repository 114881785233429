import React from 'react';
import { Wrapper } from 'src/components/GlobalUI';
import {
	IntroWrapper,
	IntroContent,
	IntroLeft,
	IntroHeadline,
	IntroDescription,
} from './Intro.dumb';

const Intro: React.FC = () => {
	return (
		<IntroWrapper id="intro">
			<Wrapper>
				<IntroContent>
					<IntroLeft>
						<IntroHeadline className="title">Early Stage Investment Fund</IntroHeadline>
						<IntroDescription>Secure your future, invest today.</IntroDescription>
					</IntroLeft>
				</IntroContent>
			</Wrapper>
		</IntroWrapper>
	);
};

export default Intro;
