import React from 'react';
import { Wrapper } from 'src/components/GlobalUI';
import {
	FooterWrapper,
	FooterContent,
	FooterCopyright,
	FooterNav,
	FooterLink,
} from './Footer.dumb';

const Footer: React.FC = () => {
	return (
		<FooterWrapper>
			<Wrapper>
				<FooterContent>
					<FooterCopyright>Copyright © 2020 KUMO Capital. All rights reserved.</FooterCopyright>
					<FooterNav>
						<FooterLink href="#portfolio">Portfolio</FooterLink>
						<FooterLink href="#thesis">Thesis</FooterLink>
						<FooterLink href="#team">Team</FooterLink>
						<FooterLink target="_blank" href="mailto:info@kumocapital.com">
							Contact us
						</FooterLink>
					</FooterNav>
				</FooterContent>
			</Wrapper>
		</FooterWrapper>
	);
};

export default Footer;
