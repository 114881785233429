import styled, { createGlobalStyle } from 'styled-components';
import { WIDTH_BREAKPOINT_MD } from 'src/utils/constants';

export const HeaderWrapper = styled.div`
	left: 0;
	position: absolute;
	right: 0;
	top: 30px;
	z-index: 1;

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		display: none;
	}
`;

export const HeaderContent = styled.header`
	align-items: end;
	display: flex;
	justify-content: space-between;
	position: relative;
`;

export const Nav = styled.nav`
	margin-left: auto;

	&.desktop {
		@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
			display: none;
		}
	}

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		display: flex;
		flex-direction: column;
		margin: 25px 0 35px;
		width: 100%;
	}
`;

export const NavLink = styled.a`
	color: #16110d;
	font-family: 'Roboto Mono', sans-serif;
	font-size: 22px;
	font-weight: 400;
	line-height: 22px;
	margin: 25px 0;

	:hover {
		text-decoration: underline;
	}

	@media (min-width: ${WIDTH_BREAKPOINT_MD}) {
		font-size: 16px;
		padding: 0 30px;

		:first-child {
			padding-left: 0;
		}

		:last-child {
			padding-right: 0;
		}
	}
`;

export const MobileNavOverlay = styled.div`
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	height: ${props => (props.isOpen ? '100vh' : '0')};
	left: 0;
	overflow: hidden;
	position: fixed;
	right: 0;
	top: 0;
	transition: all ease-in-out 0.3s;
	z-index: 1;
`;

export const MobileNavWrapper = styled.div`
	align-items: center;
	backdrop-filter: blur(20px);
	background: ${props => (props.isOpen ? '#ffbe2e' : '#ffbe2ecc')};
	display: flex;
	flex-wrap: wrap;
	height: fit-content;
	justify-content: space-between;
	padding: 20px;
	position: relative;
	position: fixed;
	top: 0;
	transition: all 0.2s;
	width: 100%;
	z-index: 1;

	@media (min-width: ${WIDTH_BREAKPOINT_MD}) {
		display: none;
	}
`;

export const OpenButton = styled.div`
	background: none;
	border: 0;
	cursor: pointer;
	height: 24px;
	position: relative;
	width: 24px;

	svg {
		position: absolute;
		transition: opacity 0.2s;

		:first-child {
			opacity: ${props => (props.isOpen ? '0' : '1')};
		}

		:last-child {
			opacity: ${props => (props.isOpen ? '1' : '0')};
		}
	}
`;

export const GlobalStyle = createGlobalStyle<{ isOpen: boolean }>`
	body {
		overflow: ${props => (props.isOpen ? 'hidden' : 'initial')};
	}
`;

export const MobileNavContainer = styled.div`
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	height: fit-content;
	justify-content: space-between;
	max-height: ${props => (props.isOpen ? '1000px' : '0')};
	overflow: hidden;
	transition: all ease-in-out 0.3s;
	width: 100%;
`;
