import styled from 'styled-components';
import { Section } from 'src/components/GlobalUI';
import MainBgPath from 'img/Main.svg';
import { WIDTH_BREAKPOINT_MD, WIDTH_BREAKPOINT_SM, WIDTH_BREAKPOINT_XS } from 'src/utils/constants';
import { titleStyle } from 'src/utils/styled';

export const IntroWrapper = styled(Section)`
	background: linear-gradient(
		180deg,
		rgba(255, 190, 46, 1) 0%,
		rgba(255, 190, 46, 1) 87%,
		rgba(255, 255, 255, 1) 87%
	);
	background-color: #ffbe2e;
	width: 100%;
`;

export const IntroContent = styled.div`
	padding: 125px 0 235px;
	position: relative;

	::after {
		background: url(${MainBgPath}) no-repeat right top;
		bottom: 0;
		content: '';
		position: absolute;
		right: 0;
		top: 80px;
		width: 100%;
		z-index: 0;

		@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
			background-position-y: bottom;
			background-size: contain;
			width: 64%;
		}
	}

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		padding: 90px 0 235px;
	}

	@media (max-width: ${WIDTH_BREAKPOINT_XS}) {
		background: url(${MainBgPath}) no-repeat 261% 100%;
		background-size: 90%;
		margin-right: -20px;

		::after {
			display: none;
		}
	}
`;

export const IntroLeft = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	width: 276px;

	@media (min-width: ${WIDTH_BREAKPOINT_MD}) {
		width: 40%;
	}
`;

export const IntroHeadline = styled.h1`
	color: white;
	font-family: 'Open Sans', sans-serif;
	font-size: 60px;
	font-weight: 700;
	line-height: 80px;
	margin-bottom: 22px;
	margin-left: -5px;
	${titleStyle('#16110D')}
	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		font-size: 48px;
		line-height: 60px;
		margin-left: -3px;
	}
`;

export const IntroDescription = styled.div`
	color: #16110d;
	font-family: 'Open Sans', sans-serif;
	font-size: 20px;
	font-weight: 600;
	line-height: 30px;

	@media (max-width: ${WIDTH_BREAKPOINT_SM}) {
		width: 80%;
	}
`;
