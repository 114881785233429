import styled from 'styled-components';
import { WIDTH_BREAKPOINT_MD } from 'src/utils/constants';

export const FooterWrapper = styled.footer`
	background: #16110d;
	padding: 15px 0;
`;

export const FooterContent = styled.div`
	align-items: center;
	display: flex;
`;

export const FooterNav = styled.nav`
	margin-left: auto;

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		display: none;
	}
`;

export const FooterLink = styled.a`
	color: #ffeecc;
	font-family: 'Roboto Mono', sans-serif;
	font-size: 14px;
	font-weight: 300;
	line-height: 22px;
	padding: 0 30px;

	:hover {
		text-decoration: underline;
	}

	:first-child {
		padding-left: 0;
	}

	:last-child {
		padding-right: 0;
	}
`;

export const FooterCopyright = styled.p`
	color: #ffeecc;
	font-family: 'Roboto Mono', sans-serif;
	font-size: 14px;
	font-weight: 300;
	line-height: 22px;
`;
